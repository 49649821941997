"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.perspectiveValueMatcher = exports.getPerspectiveMostNestedChildColumnName = exports.getPerspectiveParentColumnName = void 0;
function getPerspectiveParentColumnName(columnName) {
    const path = columnName.split('::');
    if (path.length >= 2)
        return path.slice(0, -1).join('::');
    return null;
}
exports.getPerspectiveParentColumnName = getPerspectiveParentColumnName;
function getPerspectiveMostNestedChildColumnName(columnName) {
    const path = columnName.split('::');
    return path[path.length - 1];
}
exports.getPerspectiveMostNestedChildColumnName = getPerspectiveMostNestedChildColumnName;
// export function perspectiveValueMatcher(value1, value2): boolean {
//   if (value1?.$oid && value2?.$oid) return value1.$oid == value2.$oid;
//   if (Array.isArray(value1)) return !!value1.find(x => perspectiveValueMatcher(x, value2));
//   if (Array.isArray(value2)) return !!value2.find(x => perspectiveValueMatcher(value1, x));
//   return value1 == value2;
// }
function perspectiveValueMatcher(value1, value2) {
    if ((value1 === null || value1 === void 0 ? void 0 : value1.$oid) && (value2 === null || value2 === void 0 ? void 0 : value2.$oid))
        return value1.$oid == value2.$oid;
    return value1 == value2;
}
exports.perspectiveValueMatcher = perspectiveValueMatcher;
