"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeConditions = exports.scriptToSql = exports.treeToSql = void 0;
const dumpSqlCommand_1 = require("./dumpSqlCommand");
function treeToSql(driver, object, func) {
    const dmp = driver.createDumper();
    func(dmp, object);
    return dmp.s;
}
exports.treeToSql = treeToSql;
function scriptToSql(driver, script) {
    const dmp = driver.createDumper();
    for (const cmd of script) {
        (0, dumpSqlCommand_1.dumpSqlCommand)(dmp, cmd);
        dmp.endCommand();
    }
    return dmp.s;
}
exports.scriptToSql = scriptToSql;
function mergeConditions(condition1, condition2) {
    if (!condition1)
        return condition2;
    if (!condition2)
        return condition1;
    if (condition1.conditionType == 'and' && condition2.conditionType == 'and') {
        return {
            conditionType: 'and',
            conditions: [...condition1.conditions, ...condition2.conditions],
        };
    }
    if (condition1.conditionType == 'and') {
        return {
            conditionType: 'and',
            conditions: [...condition1.conditions, condition2],
        };
    }
    if (condition2.conditionType == 'and') {
        return {
            conditionType: 'and',
            conditions: [condition1, ...condition2.conditions],
        };
    }
    return {
        conditionType: 'and',
        conditions: [condition1, condition2],
    };
}
exports.mergeConditions = mergeConditions;
