"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGridCache = exports.createGridConfig = void 0;
function createGridConfig() {
    return {
        hiddenColumns: [],
        expandedColumns: [],
        addedColumns: [],
        filters: {},
        columnWidths: {},
        sort: [],
        focusedColumns: null,
        grouping: {},
        formFilterColumns: [],
    };
}
exports.createGridConfig = createGridConfig;
function createGridCache() {
    return {
        refreshTime: new Date().getTime(),
    };
}
exports.createGridCache = createGridCache;
