import { commands } from '../stores';
import { invalidateCommandDefinitions } from './invalidateCommands';
export default function registerCommand(command) {
    const { testEnabled } = command;
    commands.update(x => {
        if (x[command.id]) {
            console.error(`Command ${command.id} already registered`);
            return x;
        }
        return Object.assign(Object.assign({}, x), { [command.id]: Object.assign(Object.assign({ text: `${command.category}: ${command.name}` }, command), { enabled: !testEnabled }) });
    });
    invalidateCommandDefinitions();
}
