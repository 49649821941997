import { runMacroOnRow } from 'dbgate-datalib';
import { changeSetContainsChanges, changeSetInsertNewRow, createChangeSet, deleteChangeSetRows, findExistingChangeSetItem, getChangeSetInsertedRows, revertChangeSetRowChanges, setChangeSetValue, setChangeSetRowData, compileMacroFunction, runMacroOnValue, changeSetInsertDocuments, } from 'dbgate-datalib';
import Grider from './Grider';
import _ from 'lodash';
function getRowFromItem(row, matchedChangeSetItem) {
    return matchedChangeSetItem.document
        ? Object.assign(Object.assign({}, matchedChangeSetItem.document), matchedChangeSetItem.fields) : Object.assign(Object.assign({}, row), matchedChangeSetItem.fields);
}
export default class ChangeSetGrider extends Grider {
    constructor(sourceRows, changeSetState, dispatchChangeSet, display, macro = null, macroArgs = {}, selectedCells = [], useRowIndexInsteaOfCondition = false) {
        super();
        this.sourceRows = sourceRows;
        this.changeSetState = changeSetState;
        this.dispatchChangeSet = dispatchChangeSet;
        this.display = display;
        this.macro = macro;
        this.macroArgs = macroArgs;
        this.selectedCells = selectedCells;
        this.useRowIndexInsteaOfCondition = useRowIndexInsteaOfCondition;
        this._errors = [];
        this.changeSet = changeSetState && changeSetState.value;
        this.insertedRows = getChangeSetInsertedRows(this.changeSet, display === null || display === void 0 ? void 0 : display.baseTableOrSimilar);
        this.setChangeSet = value => dispatchChangeSet({ type: 'set', value });
        this.rowCacheIndexes = new Set();
        this.rowDataCache = {};
        this.rowStatusCache = {};
        this.rowDefinitionsCache = {};
        this.batchChangeSet = null;
        this.compiledMacroFunc = compileMacroFunction(macro, this._errors);
        // console.log('changeSet', this.changeSet);
    }
    get errors() {
        return this._errors;
    }
    getRowSource(index) {
        if (index < this.sourceRows.length)
            return this.sourceRows[index];
        return null;
    }
    getInsertedRowIndex(index) {
        return index >= this.sourceRows.length ? index - this.sourceRows.length : null;
    }
    requireRowCache(index) {
        var _a, _b, _c;
        if (this.rowCacheIndexes.has(index))
            return;
        const row = this.getRowSource(index);
        const insertedRowIndex = this.getInsertedRowIndex(index);
        const rowDefinition = (_a = this.display) === null || _a === void 0 ? void 0 : _a.getChangeSetRow(row, insertedRowIndex, this.useRowIndexInsteaOfCondition && index < this.sourceRows.length ? index : null, this.useRowIndexInsteaOfCondition);
        const [matchedField, matchedChangeSetItem] = findExistingChangeSetItem(this.changeSet, rowDefinition);
        let rowUpdated = matchedChangeSetItem
            ? getRowFromItem(row, matchedChangeSetItem)
            : this.compiledMacroFunc
                ? Object.assign({}, row) : row;
        let status = 'regular';
        if (matchedChangeSetItem && matchedField == 'updates')
            status = 'updated';
        if (matchedField == 'deletes')
            status = 'deleted';
        if (insertedRowIndex != null)
            status = 'inserted';
        const rowStatus = {
            status,
            modifiedFields: matchedChangeSetItem && matchedChangeSetItem.fields ? new Set(Object.keys(matchedChangeSetItem.fields)) : null,
        };
        if (this.compiledMacroFunc) {
            if (((_b = this.macro) === null || _b === void 0 ? void 0 : _b.type) == 'transformValue') {
                for (const cell of this.selectedCells) {
                    if (cell.row != index)
                        continue;
                    const newValue = runMacroOnValue(this.compiledMacroFunc, this.macroArgs, rowUpdated[cell.column], index, rowUpdated, cell.column, this._errors);
                    rowUpdated[cell.column] = newValue;
                }
            }
            if (((_c = this.macro) === null || _c === void 0 ? void 0 : _c.type) == 'transformRow') {
                if (this.selectedCells.find(x => x.row == index)) {
                    rowUpdated = runMacroOnRow(this.compiledMacroFunc, this.macroArgs, index, rowUpdated, _.uniq(this.selectedCells.map(x => x.column)), this._errors);
                }
            }
        }
        this.rowDataCache[index] = rowUpdated;
        this.rowStatusCache[index] = rowStatus;
        this.rowDefinitionsCache[index] = rowDefinition;
        this.rowCacheIndexes.add(index);
    }
    get editable() {
        return this.display.editable;
    }
    get canInsert() {
        return this.useRowIndexInsteaOfCondition || !!this.display.baseTableOrCollection;
    }
    getRowData(index) {
        this.requireRowCache(index);
        return this.rowDataCache[index];
    }
    getRowStatus(index) {
        this.requireRowCache(index);
        return this.rowStatusCache[index];
    }
    get rowCount() {
        return this.sourceRows.length + this.insertedRows.length;
    }
    applyModification(changeSetReducer) {
        if (this.batchChangeSet) {
            this.batchChangeSet = changeSetReducer(this.batchChangeSet);
        }
        else {
            this.setChangeSet(changeSetReducer(this.changeSet));
        }
    }
    setCellValue(index, uniqueName, value) {
        const row = this.getRowSource(index);
        const definition = this.display.getChangeSetField(row, uniqueName, this.getInsertedRowIndex(index), this.useRowIndexInsteaOfCondition && index < this.sourceRows.length ? index : null, this.useRowIndexInsteaOfCondition);
        this.applyModification(chs => setChangeSetValue(chs, definition, value));
    }
    setRowData(index, document) {
        const row = this.getRowSource(index);
        const definition = this.display.getChangeSetRow(row, this.getInsertedRowIndex(index), this.useRowIndexInsteaOfCondition && index < this.sourceRows.length ? index : null);
        this.applyModification(chs => setChangeSetRowData(chs, definition, document));
    }
    deleteRow(index) {
        this.requireRowCache(index);
        this.applyModification(chs => deleteChangeSetRows(chs, this.rowDefinitionsCache[index]));
    }
    get rowCountInUpdate() {
        if (this.batchChangeSet) {
            const newRows = getChangeSetInsertedRows(this.batchChangeSet, this.display.baseTableOrSimilar);
            return this.sourceRows.length + newRows.length;
        }
        else {
            return this.rowCount;
        }
    }
    insertRow() {
        const res = this.rowCountInUpdate;
        this.applyModification(chs => changeSetInsertNewRow(chs, this.display.baseTableOrSimilar));
        return res;
    }
    insertDocuments(documents) {
        const res = this.rowCountInUpdate;
        this.applyModification(chs => changeSetInsertDocuments(chs, documents, this.display.baseTableOrSimilar));
        return res;
    }
    beginUpdate() {
        this.batchChangeSet = this.changeSet;
    }
    endUpdate() {
        this.setChangeSet(this.batchChangeSet);
        this.batchChangeSet = null;
    }
    revertRowChanges(index) {
        this.requireRowCache(index);
        this.applyModification(chs => revertChangeSetRowChanges(chs, this.rowDefinitionsCache[index]));
    }
    revertAllChanges() {
        this.applyModification(chs => createChangeSet());
    }
    undo() {
        this.dispatchChangeSet({ type: 'undo' });
    }
    redo() {
        this.dispatchChangeSet({ type: 'redo' });
    }
    get canUndo() {
        var _a;
        return (_a = this.changeSetState) === null || _a === void 0 ? void 0 : _a.canUndo;
    }
    get canRedo() {
        var _a;
        return (_a = this.changeSetState) === null || _a === void 0 ? void 0 : _a.canRedo;
    }
    get containsChanges() {
        return changeSetContainsChanges(this.changeSet);
    }
    get disableLoadNextPage() {
        return this.insertedRows.length > 0;
    }
}
