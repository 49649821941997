import { __awaiter } from "tslib";
class ElectronApi {
    constructor() {
        this.ipcRenderer = getIpcRenderer();
    }
    send(msg, args = null) {
        this.ipcRenderer.send(msg, args);
    }
    showOpenDialog(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ipcRenderer.invoke('showOpenDialog', options);
            return res;
        });
    }
    showSaveDialog(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ipcRenderer.invoke('showSaveDialog', options);
            return res;
        });
    }
    showItemInFolder(path) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ipcRenderer.invoke('showItemInFolder', path);
            return res;
        });
    }
    openExternal(url) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ipcRenderer.invoke('openExternal', url);
        });
    }
    invoke(route, args) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ipcRenderer.invoke(route, args);
            return res;
        });
    }
    addEventListener(channel, listener) {
        this.ipcRenderer.on(channel, listener);
    }
    removeEventListener(channel, listener) {
        this.ipcRenderer.removeListener(channel, listener);
    }
}
function getIpcRenderer() {
    if (window['require']) {
        const electron = window['require']('electron');
        return electron === null || electron === void 0 ? void 0 : electron.ipcRenderer;
    }
    return null;
}
export function isElectronAvailable() {
    return !!getIpcRenderer();
}
const apiInstance = isElectronAvailable() ? new ElectronApi() : null;
export default function getElectron() {
    return apiInstance;
    // try {
    //   // @ts-ignore
    //   return ipcRenderer;
    // } catch (e) {
    //   return null;
    // }
    // if (window['require']) {
    //   const electron = window['require']('electron');
    //   console.log('electron?.ipcRenderer', electron?.ipcRenderer);
    //   return electron?.ipcRenderer;
    // }
    // return null;
}
