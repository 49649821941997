import { __awaiter } from "tslib";
import { get } from 'svelte/store';
import { showModal } from '../modals/modalTools';
import { openedTabs } from '../stores';
import { changeTab, markTabSaved } from './common';
import SaveFileModal from '../modals/SaveFileModal.svelte';
import { apiCall } from './api';
import getElectron from './getElectron';
// export function saveTabEnabledStore(editorStore) {
//   return derived(editorStore, editor => editor != null);
// }
export default function saveTabFile(editor, saveMode, folder, format, fileExtension) {
    return __awaiter(this, void 0, void 0, function* () {
        const tabs = get(openedTabs);
        const tabid = editor.activator.tabid;
        const data = editor.getData();
        const { savedFile, savedFilePath, savedFolder } = tabs.find(x => x.tabid == tabid).props || {};
        const handleSave = () => __awaiter(this, void 0, void 0, function* () {
            if (savedFile) {
                yield apiCall('files/save', { folder: savedFolder || folder, file: savedFile, data, format });
            }
            if (savedFilePath) {
                yield apiCall('files/save-as', { filePath: savedFilePath, data, format });
            }
            markTabSaved(tabid);
        });
        const onSave = (title, newProps) => {
            changeTab(tabid, tab => (Object.assign(Object.assign({}, tab), { title, unsaved: false, props: Object.assign(Object.assign(Object.assign({}, tab.props), { savedFormat: format }), newProps) })));
        };
        if (saveMode == 'save-to-disk') {
            const electron = getElectron();
            const file = yield electron.showSaveDialog({
                filters: [
                    { name: `${fileExtension.toUpperCase()} files`, extensions: [fileExtension] },
                    { name: `All files`, extensions: ['*'] },
                ],
                defaultPath: savedFilePath || `file.${fileExtension}`,
                properties: ['showOverwriteConfirmation'],
            });
            if (file) {
                yield apiCall('files/save-as', { filePath: file, data, format });
                const path = window.require('path');
                const parsed = path.parse(file);
                onSave(parsed.name, {
                    savedFile: null,
                    savedFolder: null,
                    savedFilePath: file,
                });
            }
        }
        else if ((savedFile || savedFilePath) && saveMode == 'save') {
            handleSave();
        }
        else {
            showModal(SaveFileModal, {
                data,
                folder,
                format,
                fileExtension,
                name: savedFile || 'newFile',
                filePath: savedFilePath,
                onSave,
            });
        }
    });
}
