import { __awaiter } from "tslib";
import { analyseDataPattern } from 'dbgate-datalib';
import { writable } from 'svelte/store';
export function getPerspectiveDataPatternsFromCache(databaseConfig, config, cache, dbInfos) {
    const res = {};
    for (const node of config.nodes) {
        const conid = node.conid || databaseConfig.conid;
        const database = node.database || databaseConfig.database;
        const { schemaName, pureName } = node;
        const cached = cache.dataPatterns.find(x => x.conid == conid && x.database == database && x.schemaName == schemaName && x.pureName == pureName);
        if (cached) {
            res[node.designerId] = cached;
        }
    }
    return res;
}
export function getPerspectiveDataPatterns(databaseConfig, config, cache, dbInfos, dataLoader) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const res = {};
        for (const node of config.nodes) {
            const conid = node.conid || databaseConfig.conid;
            const database = node.database || databaseConfig.database;
            const { schemaName, pureName } = node;
            const cached = cache.dataPatterns.find(x => x.conid == conid && x.database == database && x.schemaName == schemaName && x.pureName == pureName);
            if (cached) {
                res[node.designerId] = cached;
                continue;
            }
            const db = (_a = dbInfos === null || dbInfos === void 0 ? void 0 : dbInfos[conid]) === null || _a === void 0 ? void 0 : _a[database];
            if (!db)
                continue;
            const table = (_b = db.tables) === null || _b === void 0 ? void 0 : _b.find(x => x.pureName == pureName && x.schemaName == schemaName);
            const view = (_c = db.views) === null || _c === void 0 ? void 0 : _c.find(x => x.pureName == pureName && x.schemaName == schemaName);
            const collection = (_d = db.collections) === null || _d === void 0 ? void 0 : _d.find(x => x.pureName == pureName && x.schemaName == schemaName);
            if (!table && !view && !collection)
                continue;
            // console.log('LOAD PATTERN FOR', pureName);
            const props = {
                databaseConfig: { conid, database },
                engineType: collection ? 'docdb' : 'sqldb',
                schemaName,
                pureName,
                orderBy: (table === null || table === void 0 ? void 0 : table.primaryKey)
                    ? table === null || table === void 0 ? void 0 : table.primaryKey.columns.map(x => ({ columnName: x.columnName, order: 'ASC' }))
                    : table || view
                        ? [{ columnName: (table || view).columns[0].columnName, order: 'ASC' }]
                        : null,
                range: {
                    offset: 0,
                    limit: 10,
                },
            };
            // console.log('LOAD PROPS', props);
            const rows = yield dataLoader.loadData(props);
            if (rows.errorMessage) {
                console.error('Error loading pattern for', pureName, ':', rows.errorMessage);
                continue;
            }
            // console.log('PATTERN ROWS', rows);
            const pattern = analyseDataPattern({
                conid,
                database,
                pureName,
                schemaName,
            }, rows);
            cache.dataPatterns.push(pattern);
            res[node.designerId] = pattern;
        }
        return res;
    });
}
export function usePerspectiveDataPatterns(databaseConfig, config, cache, dbInfos, dataLoader) {
    const cached = getPerspectiveDataPatternsFromCache(databaseConfig, config, cache, dbInfos);
    const promise = getPerspectiveDataPatterns(databaseConfig, config, cache, dbInfos, dataLoader);
    const res = writable(cached);
    promise.then(value => res.set(value));
    return res;
}
