"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAsyncWriteStream = void 0;
const getLogger_1 = require("./getLogger");
const logger = (0, getLogger_1.getLogger)('asyncWriteStream');
function createAsyncWriteStream(stream, options) {
    const writable = new stream.Writable({
        objectMode: true,
    });
    writable._write = (chunk, encoding, callback) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield options.processItem(chunk);
            callback(null);
        }
        catch (err) {
            callback(err);
        }
    });
    // writable._final = async callback => {
    //   callback();
    // };
    return writable;
}
exports.createAsyncWriteStream = createAsyncWriteStream;
