export default class DomTableRef {
    constructor(table, domRefs, domWrapper, settings) {
        this.domTable = domRefs[''];
        this.domWrapper = domWrapper;
        this.table = table;
        this.designerId = table.designerId;
        this.domRefs = domRefs;
        this.settings = settings;
    }
    getRect() {
        if (!this.domWrapper)
            return null;
        if (!this.domTable)
            return null;
        const wrap = this.domWrapper.getBoundingClientRect();
        const rect = this.domTable.getBoundingClientRect();
        return {
            left: rect.left - wrap.left,
            top: rect.top - wrap.top,
            right: rect.right - wrap.left,
            bottom: rect.bottom - wrap.top,
        };
    }
    getColumnY(columnName) {
        var _a, _b, _c;
        let col = this.domRefs[columnName];
        while (col == null && ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.getParentColumnName) && ((_b = this.settings) === null || _b === void 0 ? void 0 : _b.getParentColumnName(columnName))) {
            columnName = (_c = this.settings) === null || _c === void 0 ? void 0 : _c.getParentColumnName(columnName);
            col = this.domRefs[columnName];
        }
        const tableRect = this.getRect();
        if (!col)
            return tableRect.top + 12;
        const rect = col.getBoundingClientRect();
        const wrap = this.domWrapper.getBoundingClientRect();
        let res = (rect.top + rect.bottom) / 2 - wrap.top;
        if (res < tableRect.top)
            res = tableRect.top;
        if (res > tableRect.bottom)
            res = tableRect.bottom;
        return res;
    }
}
