import { __awaiter } from "tslib";
import { getDbCore, getConnectionInfo, getSqlObjectInfo } from './metadataLoaders';
import sqlFormatter from 'sql-formatter';
import { driverBase, findEngineDriver } from 'dbgate-tools';
function generateTableSql(extensions, props, dumpProc, format = false) {
    return __awaiter(this, void 0, void 0, function* () {
        const tableInfo = yield getDbCore(props, props.objectTypeField || 'tables');
        const connection = yield getConnectionInfo(props);
        const driver = findEngineDriver(connection, extensions) || driverBase;
        const dmp = driver.createDumper();
        if (tableInfo)
            dumpProc(dmp, tableInfo);
        return format ? sqlFormatter.format(dmp.s) : dmp.s;
    });
}
export default function applyScriptTemplate(scriptTemplate, extensions, props) {
    return __awaiter(this, void 0, void 0, function* () {
        if (scriptTemplate == 'CREATE TABLE') {
            return generateTableSql(extensions, props, (dmp, tableInfo) => dmp.createTable(tableInfo));
        }
        if (scriptTemplate == 'SELECT') {
            return generateTableSql(extensions, props, (dmp, tableInfo) => {
                dmp.put('^select %,i ^from %f', tableInfo.columns.map(x => x.columnName), tableInfo);
            }, true);
        }
        if (scriptTemplate == 'CREATE OBJECT') {
            const objectInfo = yield getSqlObjectInfo(props);
            if (objectInfo) {
                if (objectInfo.requiresFormat && objectInfo.createSql)
                    return sqlFormatter.format(objectInfo.createSql);
                else
                    return objectInfo.createSql;
            }
        }
        if (scriptTemplate == 'ALTER OBJECT') {
            const objectInfo = yield getSqlObjectInfo(props);
            if (objectInfo) {
                const createSql = objectInfo.requiresFormat && objectInfo.createSql
                    ? sqlFormatter.format(objectInfo.createSql)
                    : objectInfo.createSql || '';
                const alterPrefix = createSql.trimStart().startsWith('CREATE ') ? 'ALTER ' : 'alter ';
                return createSql.replace(/^\s*create\s+/i, alterPrefix);
            }
        }
        if (scriptTemplate == 'EXECUTE PROCEDURE') {
            const procedureInfo = yield getSqlObjectInfo(props);
            const connection = yield getConnectionInfo(props);
            const driver = findEngineDriver(connection, extensions) || driverBase;
            const dmp = driver.createDumper();
            if (procedureInfo)
                dmp.put('^execute %f', procedureInfo);
            return dmp.s;
        }
        if (scriptTemplate == 'dropCollection') {
            return `db.collection('${props.pureName}').drop()`;
        }
        if (scriptTemplate == 'findCollection') {
            return `db.collection('${props.pureName}').find()`;
        }
        return null;
    });
}
