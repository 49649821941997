import { __awaiter } from "tslib";
import _ from 'lodash';
import { apiCall } from './api';
import { filterAppsForDatabase, saveDbToApp } from './appTools';
function checkDescriptionColumns(columns, table) {
    var _a, _b;
    if (!(columns === null || columns === void 0 ? void 0 : columns.length))
        return false;
    if (!columns.every(x => table.columns.find(y => y.columnName == x)))
        return false;
    if ((_b = (_a = table.primaryKey) === null || _a === void 0 ? void 0 : _a.columns) === null || _b === void 0 ? void 0 : _b.find(x => columns.includes(x.columnName)))
        return false;
    return true;
}
export function getDictionaryDescription(table, conid, database, apps, connections, skipCheckSaved = false) {
    const conn = connections === null || connections === void 0 ? void 0 : connections.find(x => x._id == conid);
    if (!conn) {
        return null;
    }
    const dbApps = filterAppsForDatabase(conn, database, apps);
    if (!dbApps) {
        return null;
    }
    const cached = _.flatten(dbApps.map(x => x.dictionaryDescriptions || [])).find(x => x.pureName == table.pureName && x.schemaName == table.schemaName);
    if (cached && (skipCheckSaved || checkDescriptionColumns(cached.columns, table))) {
        return cached;
    }
    const descColumn = table.columns.find(x => { var _a, _b; return (_b = (_a = x === null || x === void 0 ? void 0 : x.dataType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes('char'); });
    if (descColumn) {
        return {
            columns: [descColumn.columnName],
            delimiter: null,
            expression: descColumn.columnName,
        };
    }
    return null;
}
export function parseDelimitedColumnList(columns) {
    return _.compact((columns || '').split(',').map(x => x.trim()));
}
export function checkDescriptionExpression(expression, table) {
    if (!expression)
        return false;
    if (!table)
        return false;
    return checkDescriptionColumns(parseDelimitedColumnList(expression), table);
}
export function changeDelimitedColumnList(columns, columnName, isChecked) {
    const parsed = parseDelimitedColumnList(columns);
    const includes = parsed.includes(columnName);
    if (includes == isChecked)
        return columns;
    if (isChecked)
        parsed.push(columnName);
    else
        _.remove(parsed, x => x == columnName);
    return parsed.join(',');
}
export function saveDictionaryDescription(table, conid, database, expression, delimiter, targetApplication) {
    return __awaiter(this, void 0, void 0, function* () {
        const appFolder = yield saveDbToApp(conid, database, targetApplication);
        yield apiCall('apps/save-dictionary-description', {
            appFolder,
            schemaName: table.schemaName,
            pureName: table.pureName,
            columns: parseDelimitedColumnList(expression),
            expression,
            delimiter,
        });
    });
}
