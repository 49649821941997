import { __awaiter } from "tslib";
import { tick } from 'svelte';
import { commands } from '../stores';
let isInvalidated = false;
export default function invalidateCommands() {
    return __awaiter(this, void 0, void 0, function* () {
        if (isInvalidated)
            return;
        isInvalidated = true;
        yield tick();
        isInvalidated = false;
        commands.update(dct => {
            let res = null;
            for (const command of Object.values(dct)) {
                if (command.isGroupCommand)
                    continue;
                const { testEnabled } = command;
                let enabled = command.enabled;
                if (testEnabled)
                    enabled = testEnabled();
                if (enabled != command.enabled) {
                    if (!res)
                        res = Object.assign({}, dct);
                    res[command.id].enabled = enabled;
                }
            }
            if (res) {
                const values = Object.values(res);
                // test enabled for group commands
                for (const command of values) {
                    if (!command.isGroupCommand)
                        continue;
                    const groupSources = values.filter(x => x.group == command.group && !x.isGroupCommand && x.enabled);
                    command.enabled = groupSources.length > 0;
                    // for (const source of groupSources) {
                    //   source.keyTextFromGroup = command.keyText;
                    // }
                }
            }
            return res || dct;
        });
    });
}
let isInvalidatedDefinitions = false;
export function invalidateCommandDefinitions() {
    return __awaiter(this, void 0, void 0, function* () {
        if (isInvalidatedDefinitions)
            return;
        isInvalidatedDefinitions = true;
        yield tick();
        isInvalidatedDefinitions = false;
        commands.update(dct => {
            let res = Object.assign({}, dct);
            const values = Object.values(res);
            // test enabled for group commands
            for (const command of values) {
                if (!command.isGroupCommand)
                    continue;
                const groupSources = values.filter(x => x.group == command.group && !x.isGroupCommand);
                for (const source of groupSources) {
                    source.keyTextFromGroup = command.keyText;
                }
            }
            return res;
        });
        invalidateCommands();
    });
}
