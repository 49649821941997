export default class Grider {
    getRowStatus(index) {
        const res = {
            status: 'regular',
        };
        return res;
    }
    beginUpdate() { }
    endUpdate() { }
    setCellValue(index, uniqueName, value) { }
    deleteRow(index) { }
    insertRow() {
        return null;
    }
    insertDocuments(documents) {
        return null;
    }
    revertRowChanges(index) { }
    revertAllChanges() { }
    undo() { }
    redo() { }
    get editable() {
        return false;
    }
    get canInsert() {
        return false;
    }
    get allowSave() {
        return this.containsChanges;
    }
    get rowCountInUpdate() {
        return this.rowCount;
    }
    get canUndo() {
        return false;
    }
    get canRedo() {
        return false;
    }
    get containsChanges() {
        return false;
    }
    get disableLoadNextPage() {
        return false;
    }
    get errors() {
        return null;
    }
    updateRow(index, changeObject) {
        for (const key of Object.keys(changeObject)) {
            this.setCellValue(index, key, changeObject[key]);
        }
    }
}
