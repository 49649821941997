export default function createRef(value) {
    return {
        value,
        initValue: value,
        set(value) {
            this.value = value;
        },
        reset() {
            this.value = this.initValue;
        },
        get() {
            return this.value;
        },
        update(func) {
            this.value = func(this.value);
        },
    };
}
