import Grider from './Grider';
export default class RowsArrayGrider extends Grider {
    constructor(rows) {
        super();
        this.rows = rows;
    }
    getRowData(index) {
        return this.rows[index];
    }
    get rowCount() {
        return this.rows.length;
    }
}
