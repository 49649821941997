import { presetPalettes, presetDarkPalettes } from '@ant-design/colors';
import { derived } from 'svelte/store';
import { currentThemeDefinition } from '../stores';
import { useConnectionList } from '../utility/metadataLoaders';
export function getConnectionColor(connections, dbid, themeType, colorIndex, backgroundStyle = false, useConnectionFallback = true) {
    if (!dbid || !connections)
        return undefined;
    const current = connections.find(x => x._id == dbid.conid);
    const { database } = dbid;
    let colorName = useConnectionFallback || !database ? current === null || current === void 0 ? void 0 : current.connectionColor : null;
    const dbConfig = ((current === null || current === void 0 ? void 0 : current.databases) || []).find(x => x.name == database);
    if (dbConfig === null || dbConfig === void 0 ? void 0 : dbConfig.connectionColor) {
        colorName = dbConfig.connectionColor;
    }
    if (!colorName)
        return undefined;
    const palettes = themeType == 'dark' ? presetDarkPalettes : presetPalettes;
    if (colorIndex == null)
        return colorName;
    const color = palettes[colorName][colorIndex];
    if (backgroundStyle)
        return `background:${color}`;
    return color;
}
export function useConnectionColor(dbid, colorIndex, themeType = null, backgroundStyle = false, useConnectionFallback = true) {
    const connections = useConnectionList();
    return derived([connections, currentThemeDefinition], ([$connections, $themeDef]) => getConnectionColor($connections, dbid, themeType !== null && themeType !== void 0 ? themeType : $themeDef === null || $themeDef === void 0 ? void 0 : $themeDef.themeType, colorIndex, backgroundStyle, useConnectionFallback));
}
export function useConnectionColorFactory(colorIndex, themeType = null, backgroundStyle = false, useConnectionFallback = true) {
    const connections = useConnectionList();
    return derived([connections, currentThemeDefinition], ([$connections, $themeDef]) => (dbid, colorIndexOverride = null, backgroundStyleOverride = null, useConnectionFallbackOverride = null) => getConnectionColor($connections, dbid, themeType !== null && themeType !== void 0 ? themeType : $themeDef === null || $themeDef === void 0 ? void 0 : $themeDef.themeType, colorIndexOverride !== null && colorIndexOverride !== void 0 ? colorIndexOverride : colorIndex, backgroundStyleOverride !== null && backgroundStyleOverride !== void 0 ? backgroundStyleOverride : backgroundStyle, useConnectionFallbackOverride !== null && useConnectionFallbackOverride !== void 0 ? useConnectionFallbackOverride : useConnectionFallback));
}
