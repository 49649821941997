"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dumpSqlFromDefinition = exports.dumpSqlRelation = exports.dumpSqlSourceRef = exports.dumpSqlSourceDef = void 0;
const dumpSqlCommand_1 = require("./dumpSqlCommand");
const dumpSqlCondition_1 = require("./dumpSqlCondition");
function dumpSqlSourceDef(dmp, source) {
    let sources = 0;
    if (source.name != null)
        sources++;
    if (source.subQuery != null)
        sources++;
    if (source.subQueryString != null)
        sources++;
    if (sources != 1)
        throw new Error('sqltree.Source should have exactly one source');
    if (source.name != null) {
        dmp.put('%f', source.name);
    }
    if (source.subQuery) {
        dmp.put('(');
        (0, dumpSqlCommand_1.dumpSqlSelect)(dmp, source.subQuery);
        dmp.put(')');
    }
    if (source.subQueryString) {
        dmp.put('(');
        dmp.putRaw(source.subQueryString);
        dmp.put(')');
    }
    if (source.alias) {
        dmp.put(' %i', source.alias);
    }
}
exports.dumpSqlSourceDef = dumpSqlSourceDef;
function dumpSqlSourceRef(dmp, source) {
    if (source.alias) {
        dmp.put('%i', source.alias);
        return true;
    }
    else if (source.name) {
        dmp.put('%f', source.name);
        return true;
    }
    return false;
}
exports.dumpSqlSourceRef = dumpSqlSourceRef;
function dumpSqlRelation(dmp, from) {
    dmp.put('&n %k ', from.joinType);
    dumpSqlSourceDef(dmp, from);
    if (from.conditions && from.conditions.length > 0) {
        dmp.put(' ^on ');
        dmp.putCollection(' ^and ', from.conditions, cond => (0, dumpSqlCondition_1.dumpSqlCondition)(dmp, cond));
    }
}
exports.dumpSqlRelation = dumpSqlRelation;
function dumpSqlFromDefinition(dmp, from) {
    dumpSqlSourceDef(dmp, from);
    dmp.put(' ');
    if (from.relations)
        from.relations.forEach(rel => dumpSqlRelation(dmp, rel));
}
exports.dumpSqlFromDefinition = dumpSqlFromDefinition;
