import { derived } from 'svelte/store';
import { useDatabaseInfo } from './metadataLoaders';
export function useMultipleDatabaseInfo(dbs) {
    return derived(dbs.map(db => useDatabaseInfo(db)), values => {
        let res = {};
        for (let i = 0; i < dbs.length; i++) {
            const { conid, database } = dbs[i];
            const dbInfo = values[i];
            res = Object.assign(Object.assign({}, res), { [conid]: Object.assign(Object.assign({}, res[conid]), { [database]: dbInfo }) });
        }
        return res;
    });
}
