"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFilterType = void 0;
const dbgate_tools_1 = require("dbgate-tools");
function getFilterType(dataType) {
    if (!dataType)
        return 'string';
    if ((0, dbgate_tools_1.isTypeNumber)(dataType))
        return 'number';
    if ((0, dbgate_tools_1.isTypeString)(dataType))
        return 'string';
    if ((0, dbgate_tools_1.isTypeLogical)(dataType))
        return 'logical';
    if ((0, dbgate_tools_1.isTypeDateTime)(dataType))
        return 'datetime';
    return 'string';
}
exports.getFilterType = getFilterType;
