"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTypeDateTime = exports.isTypeLogical = exports.isTypeString = exports.isTypeNumber = exports.isTypeFloat = exports.isTypeNumeric = exports.isTypeInteger = void 0;
function isTypeInteger(dataType) {
    return dataType && /int/i.test(dataType);
}
exports.isTypeInteger = isTypeInteger;
function isTypeNumeric(dataType) {
    return dataType && /numeric|decimal/i.test(dataType);
}
exports.isTypeNumeric = isTypeNumeric;
function isTypeFloat(dataType) {
    return dataType && /float|single|double/i.test(dataType);
}
exports.isTypeFloat = isTypeFloat;
function isTypeNumber(dataType) {
    return isTypeInteger(dataType) || isTypeFloat(dataType) || isTypeNumeric(dataType);
}
exports.isTypeNumber = isTypeNumber;
function isTypeString(dataType) {
    return dataType && /char|binary/i.test(dataType);
}
exports.isTypeString = isTypeString;
function isTypeLogical(dataType) {
    return dataType && /bit|boolean/i.test(dataType);
}
exports.isTypeLogical = isTypeLogical;
function isTypeDateTime(dataType) {
    return dataType && /date|time|timestamp/i.test(dataType);
}
exports.isTypeDateTime = isTypeDateTime;
