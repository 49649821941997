import { openedSnackbars } from '../stores';
let lastSnackbarId = 0;
export function showSnackbar(snackbar) {
    lastSnackbarId += 1;
    const id = lastSnackbarId.toString();
    openedSnackbars.update(x => [
        ...x,
        Object.assign(Object.assign({}, snackbar), { id }),
    ]);
    return id;
}
export function showSnackbarSuccess(message) {
    showSnackbar({
        message,
        icon: 'img ok',
        allowClose: true,
        autoClose: true,
    });
}
export function showSnackbarInfo(message) {
    showSnackbar({
        message,
        icon: 'img info',
        allowClose: true,
        autoClose: true,
    });
}
export function showSnackbarError(message) {
    showSnackbar({
        message,
        icon: 'img error',
        allowClose: true,
        autoClose: true,
    });
}
export function closeSnackbar(snackId) {
    openedSnackbars.update(x => x.filter(x => x.id != snackId));
}
//   showSnackbar({
//     icon: 'img ok',
//     message: 'Test snackbar',
//     allowClose: true,
//   });
// showSnackbar({
//   icon: 'img ok',
//   message: 'Auto close',
//   autoClose: true,
// });
//   showSnackbar({
//     icon: 'img warn',
//     message: 'Buttons',
//     buttons: [{ label: 'OK', onClick: () => console.log('OK') }],
//   });
