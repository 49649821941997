import { __awaiter } from "tslib";
import _ from 'lodash';
import { findEngineDriver, generateDbPairingId, getAlterDatabaseScript } from 'dbgate-tools';
import InputTextModal from '../modals/InputTextModal.svelte';
import { showModal } from '../modals/modalTools';
import { getExtensions } from '../stores';
import { getConnectionInfo, getDatabaseInfo } from './metadataLoaders';
import ConfirmSqlModal, { saveScriptToDatabase } from '../modals/ConfirmSqlModal.svelte';
export function alterDatabaseDialog(conid, database, updateFunc) {
    return __awaiter(this, void 0, void 0, function* () {
        const conn = yield getConnectionInfo({ conid });
        const driver = findEngineDriver(conn, getExtensions());
        const db = generateDbPairingId(yield getDatabaseInfo({ conid, database }));
        const dbUpdated = _.cloneDeep(db);
        updateFunc(dbUpdated);
        const { sql, recreates } = getAlterDatabaseScript(db, dbUpdated, {}, db, dbUpdated, driver);
        showModal(ConfirmSqlModal, {
            sql,
            recreates,
            onConfirm: () => __awaiter(this, void 0, void 0, function* () {
                saveScriptToDatabase({ conid, database }, sql);
            }),
            engine: driver.engine,
        });
    });
}
export function renameDatabaseObjectDialog(conid, database, oldName, updateFunc) {
    return __awaiter(this, void 0, void 0, function* () {
        showModal(InputTextModal, {
            value: oldName,
            label: 'New name',
            header: 'Rename object',
            onConfirm: newName => {
                alterDatabaseDialog(conid, database, db => updateFunc(db, newName));
            },
        });
    });
}
