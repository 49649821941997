import { __awaiter } from "tslib";
import { get } from 'svelte/store';
import getElectron from './getElectron';
import { currentArchive, extensions, selectedWidget, visibleWidgetSideBar } from '../stores';
import { showSnackbarSuccess } from './snackbar';
import { apiCall } from './api';
export function openArchiveFolder() {
    return __awaiter(this, void 0, void 0, function* () {
        const electron = getElectron();
        const ext = get(extensions);
        const filePaths = yield electron.showOpenDialog({
            properties: ['openDirectory'],
        });
        const linkedFolder = filePaths && filePaths[0];
        if (!linkedFolder)
            return;
        const resp = yield apiCall('archive/create-link', { linkedFolder });
        currentArchive.set(resp);
        selectedWidget.set('archive');
        visibleWidgetSideBar.set(true);
        showSnackbarSuccess(`Created link ${resp}`);
    });
}
