import { __awaiter } from "tslib";
import uuidv1 from 'uuid/v1';
import openNewTab from './openNewTab';
export function openJsonLinesData(rows) {
    return __awaiter(this, void 0, void 0, function* () {
        const jslid = uuidv1();
        // await apiCall('jsldata/save-rows', { jslid, rows });
        openNewTab({
            tabComponent: 'ArchiveFileTab',
            icon: 'img archive',
            title: 'Data #',
            props: {
                jslid,
            },
        }, {
            rows,
        });
    });
}
