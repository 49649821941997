"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processPerspectiveDefaultColunns = exports.shouldProcessPerspectiveDefaultColunns = exports.perspectiveNodesHaveStructure = void 0;
const dbgate_tools_1 = require("dbgate-tools");
const PerspectiveTreeNode_1 = require("./PerspectiveTreeNode");
const namePredicates = [
    x => x.toLowerCase() == 'name',
    x => x.toLowerCase() == 'title',
    x => x.toLowerCase().includes('name'),
    x => x.toLowerCase().includes('title'),
    x => x.toLowerCase().includes('subject'),
];
function getPerspectiveDefaultColumns(table, db, circularColumns) {
    const columns = table.columns.map(x => x.columnName);
    const predicates = [
        ...namePredicates,
        x => {
            var _a, _b, _c;
            return (_c = (_b = (_a = table.columns
                .find(y => y.columnName == x)) === null || _a === void 0 ? void 0 : _a.dataType) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === null || _c === void 0 ? void 0 : _c.includes('char');
        },
    ];
    for (const predicate of predicates) {
        const col = columns.find(predicate);
        if (col)
            return [[col], null];
    }
    if (circularColumns) {
        const keyPredicates = [
            x => { var _a, _b; return ((_b = (_a = (0, dbgate_tools_1.findForeignKeyForColumn)(table, x)) === null || _a === void 0 ? void 0 : _a.columns) === null || _b === void 0 ? void 0 : _b.length) == 1 && !circularColumns.includes(x); },
            x => { var _a, _b; return ((_b = (_a = (0, dbgate_tools_1.findForeignKeyForColumn)(table, x)) === null || _a === void 0 ? void 0 : _a.columns) === null || _b === void 0 ? void 0 : _b.length) == 1; },
        ];
        for (const predicate of keyPredicates) {
            const col = columns.find(predicate);
            if (col)
                return [null, [col]];
        }
    }
    return [[columns[0]], null];
}
function getPerspectiveDefaultCollectionColumns(pattern) {
    const columns = pattern.columns.map(x => x.name);
    const predicates = [...namePredicates, x => { var _a, _b; return (_b = (_a = pattern.columns.find(y => y.name == x)) === null || _a === void 0 ? void 0 : _a.types) === null || _b === void 0 ? void 0 : _b.includes('string'); }];
    for (const predicate of predicates) {
        const col = columns.find(predicate);
        if (col)
            return [col];
    }
}
function perspectiveNodesHaveStructure(config, dbInfos, dataPatterns, conid, database) {
    var _a;
    for (const node of config.nodes) {
        const db = (_a = dbInfos === null || dbInfos === void 0 ? void 0 : dbInfos[node.conid || conid]) === null || _a === void 0 ? void 0 : _a[node.database || database];
        if (!db)
            return false;
        const table = db.tables.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        const view = db.views.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        const collection = db.collections.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        if (!table && !view && !collection)
            return false;
        if (collection && !(dataPatterns === null || dataPatterns === void 0 ? void 0 : dataPatterns[node.designerId]))
            return false;
    }
    return true;
}
exports.perspectiveNodesHaveStructure = perspectiveNodesHaveStructure;
function shouldProcessPerspectiveDefaultColunns(config, dbInfos, dataPatterns, conid, database) {
    const nodesNotProcessed = config.nodes.filter(x => !x.defaultColumnsProcessed);
    if (nodesNotProcessed.length == 0)
        return false;
    return perspectiveNodesHaveStructure(config, dbInfos, dataPatterns, conid, database);
}
exports.shouldProcessPerspectiveDefaultColunns = shouldProcessPerspectiveDefaultColunns;
function processPerspectiveDefaultColunnsStep(config, dbInfos, dataPatterns, conid, database) {
    var _a, _b;
    const rootNode = config.nodes.find(x => x.designerId == config.rootDesignerId);
    if (!rootNode)
        return null;
    const rootDb = (_a = dbInfos === null || dbInfos === void 0 ? void 0 : dbInfos[rootNode.conid || conid]) === null || _a === void 0 ? void 0 : _a[rootNode.database || database];
    if (!rootDb)
        return null;
    const rootTable = rootDb.tables.find(x => x.pureName == rootNode.pureName && x.schemaName == rootNode.schemaName);
    const rootView = rootDb.views.find(x => x.pureName == rootNode.pureName && x.schemaName == rootNode.schemaName);
    const root = new PerspectiveTreeNode_1.PerspectiveTableNode(rootTable || rootView, dbInfos, config, null, null, { conid, database }, null, config.rootDesignerId);
    for (const node of config.nodes) {
        if (node.defaultColumnsProcessed)
            continue;
        const db = (_b = dbInfos === null || dbInfos === void 0 ? void 0 : dbInfos[node.conid || conid]) === null || _b === void 0 ? void 0 : _b[node.database || database];
        if (!db)
            continue;
        const table = db.tables.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        const view = db.views.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        const collection = db.collections.find(x => x.pureName == node.pureName && x.schemaName == node.schemaName);
        if (table || view) {
            const treeNode = root.findNodeByDesignerId(node.designerId);
            if (!treeNode) {
                const [defaultColumns] = getPerspectiveDefaultColumns(table || view, db, null);
                return Object.assign(Object.assign({}, config), { nodes: config.nodes.map(n => n.designerId == node.designerId
                        ? Object.assign(Object.assign({}, n), { defaultColumnsProcessed: true, checkedColumns: defaultColumns }) : n) });
            }
            const circularColumns = treeNode.childNodes.filter(x => x.isCircular).map(x => x.columnName);
            const [defaultColumns, defaultRefs] = getPerspectiveDefaultColumns(table || view, db, circularColumns);
            if (defaultRefs) {
                const childNode = treeNode.childNodes.find(x => x.columnName == defaultRefs[0]);
                if (childNode === null || childNode === void 0 ? void 0 : childNode.designerId) {
                    return Object.assign(Object.assign({}, config), { nodes: config.nodes.map(n => n.designerId == childNode.designerId
                            ? Object.assign(Object.assign({}, n), { isNodeChecked: true }) : n.designerId == node.designerId
                            ? Object.assign(Object.assign({}, n), { defaultColumnsProcessed: true }) : n) });
                }
                else if (childNode) {
                    const [newConfig, nodeConfig] = childNode.ensureNodeConfig(config);
                    nodeConfig.isNodeChecked = true;
                    return Object.assign(Object.assign({}, newConfig), { nodes: newConfig.nodes.map(n => n.designerId == node.designerId
                            ? Object.assign(Object.assign({}, n), { defaultColumnsProcessed: true }) : n) });
                }
            }
            else {
                return Object.assign(Object.assign({}, config), { nodes: config.nodes.map(n => n.designerId == node.designerId
                        ? Object.assign(Object.assign({}, n), { defaultColumnsProcessed: true, checkedColumns: defaultColumns }) : n) });
            }
        }
        if (collection) {
            const defaultColumns = getPerspectiveDefaultCollectionColumns(dataPatterns === null || dataPatterns === void 0 ? void 0 : dataPatterns[node.designerId]);
            return Object.assign(Object.assign({}, config), { nodes: config.nodes.map(n => n.designerId == node.designerId
                    ? Object.assign(Object.assign({}, n), { defaultColumnsProcessed: true, checkedColumns: defaultColumns }) : n) });
        }
    }
    return null;
}
function markAllProcessed(config) {
    return Object.assign(Object.assign({}, config), { nodes: config.nodes.map(x => (Object.assign(Object.assign({}, x), { defaultColumnsProcessed: true }))) });
}
function processPerspectiveDefaultColunns(config, dbInfos, dataPatterns, conid, database) {
    while (config.nodes.filter(x => !x.defaultColumnsProcessed).length > 0) {
        const newConfig = processPerspectiveDefaultColunnsStep(config, dbInfos, dataPatterns, conid, database);
        if (!newConfig) {
            return markAllProcessed(config);
        }
        if (newConfig.nodes.filter(x => x.defaultColumnsProcessed).length <=
            config.nodes.filter(x => x.defaultColumnsProcessed).length) {
            return markAllProcessed(config);
        }
        config = newConfig;
    }
    return markAllProcessed(config);
}
exports.processPerspectiveDefaultColunns = processPerspectiveDefaultColunns;
