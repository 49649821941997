import { getCurrentArchive, getExtensions } from '../stores';
export function createQuickExportMenuItems(handler, advancedExportMenuItem) {
    const extensions = getExtensions();
    return [
        ...extensions.quickExports.map(fmt => ({
            text: fmt.label,
            onClick: handler(fmt),
        })),
        { divider: true },
        {
            text: 'Current archive',
            onClick: handler({
                extension: 'jsonl',
                label: 'Current archive',
                noFilenameDependency: true,
                createWriter: (fileName, dataName) => ({
                    functionName: 'archiveWriter',
                    props: {
                        fileName: dataName,
                        folderName: getCurrentArchive(),
                    },
                }),
            }),
        },
        { divider: true },
        Object.assign({ text: 'More...' }, advancedExportMenuItem),
    ];
}
export default function createQuickExportMenu(handler, advancedExportMenuItem) {
    return {
        text: 'Export',
        submenu: createQuickExportMenuItems(handler, advancedExportMenuItem),
    };
}
