import { __awaiter } from "tslib";
import _ from 'lodash';
import { showModal } from '../modals/modalTools';
import CustomJoinModal from './CustomJoinModal.svelte';
import InputTextModal from '../modals/InputTextModal.svelte';
import runCommand from '../commands/runCommand';
import { tick } from 'svelte';
export function setPerspectiveSort(cfg, designerId, columnName, order) {
    return Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == designerId
            ? Object.assign(Object.assign({}, n), { sort: [{ columnName, order }] }) : n) });
}
export function addToPerspectiveSort(cfg, designerId, columnName, order) {
    return Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == designerId
            ? Object.assign(Object.assign({}, n), { sort: [...(n.sort || []).filter(x => x.columnName != columnName), { columnName, order }] }) : n) });
}
export function clearPerspectiveSort(cfg, designerId) {
    return Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == designerId
            ? Object.assign(Object.assign({}, n), { sort: [] }) : n) });
}
export function addToPerspectiveFilter(cfg, designerId, columnName, filterValue = '') {
    return Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == designerId
            ? Object.assign(Object.assign({}, n), { filters: Object.assign(Object.assign({}, n.filters), { [columnName]: filterValue }) }) : n) });
}
export function setPerspectiveTableAlias(cfg, designerId, alias) {
    return Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == designerId
            ? Object.assign(Object.assign({}, n), { alias }) : n) });
}
export function getPerspectiveNodeMenu(props) {
    var _a, _b, _c, _d;
    const { node, conid, database, root, config, setConfig, designerId } = props;
    const customJoin = node === null || node === void 0 ? void 0 : node.customJoinConfig;
    const filterInfo = node === null || node === void 0 ? void 0 : node.filterInfo;
    const parentDesignerId = ((_a = node === null || node === void 0 ? void 0 : node.parentNode) === null || _a === void 0 ? void 0 : _a.designerId) || '';
    const columnName = node === null || node === void 0 ? void 0 : node.columnName;
    const sort = (_c = (_b = config.nodes) === null || _b === void 0 ? void 0 : _b.find(x => x.designerId == parentDesignerId)) === null || _c === void 0 ? void 0 : _c.sort;
    const order = (_d = sort === null || sort === void 0 ? void 0 : sort.find(x => x.columnName == columnName)) === null || _d === void 0 ? void 0 : _d.order;
    const orderIndex = (sort === null || sort === void 0 ? void 0 : sort.length) > 1 ? _.findIndex(sort, x => x.columnName == columnName) : -1;
    const isSortDefined = (sort === null || sort === void 0 ? void 0 : sort.length) > 0;
    const nodeConfig = config.nodes.find(x => x.designerId == designerId);
    const setSort = order => {
        setConfig(cfg => setPerspectiveSort(cfg, parentDesignerId, columnName, order), true);
    };
    const addToSort = order => {
        setConfig(cfg => addToPerspectiveSort(cfg, parentDesignerId, columnName, order), true);
    };
    const clearSort = () => {
        setConfig(cfg => clearPerspectiveSort(cfg, parentDesignerId), true);
    };
    return [
        (node === null || node === void 0 ? void 0 : node.isSortable) && { onClick: () => setSort('ASC'), text: 'Sort ascending' },
        (node === null || node === void 0 ? void 0 : node.isSortable) && { onClick: () => setSort('DESC'), text: 'Sort descending' },
        (node === null || node === void 0 ? void 0 : node.isSortable) && isSortDefined && !order && { onClick: () => addToSort('ASC'), text: 'Add to sort - ascending' },
        (node === null || node === void 0 ? void 0 : node.isSortable) &&
            isSortDefined &&
            !order && { onClick: () => addToSort('DESC'), text: 'Add to sort - descending' },
        (node === null || node === void 0 ? void 0 : node.isSortable) && order && { onClick: () => clearSort(), text: 'Clear sort criteria' },
        { divider: true },
        filterInfo && {
            text: 'Add to filter',
            onClick: () => setConfig(cfg => addToPerspectiveFilter(cfg, parentDesignerId, columnName)),
        },
        customJoin && {
            text: 'Remove custom join',
            onClick: () => setConfig(cfg => (Object.assign(Object.assign({}, cfg), { nodes: (cfg.nodes || []).filter(x => x.designerId != customJoin.refNodeDesignerId), references: (cfg.references || []).filter(x => x.designerId != customJoin.referenceDesignerId) }))),
        },
        customJoin && {
            text: 'Edit custom join',
            onClick: () => showModal(CustomJoinModal, {
                config,
                setConfig,
                conid,
                database,
                root,
                editValue: customJoin,
            }),
        },
        (node === null || node === void 0 ? void 0 : node.supportsParentFilter) && {
            text: node.isParentFilter ? 'Cancel filter parent rows' : 'Filter parent rows',
            onClick: () => {
                setConfig(cfg => (Object.assign(Object.assign({}, cfg), { nodes: cfg.nodes.map(n => n.designerId == (node === null || node === void 0 ? void 0 : node.designerId) ? Object.assign(Object.assign({}, n), { isParentFilter: !node.isParentFilter }) : n) })), true);
            },
        },
        nodeConfig && [
            {
                text: 'Set alias',
                onClick: () => {
                    var _a;
                    showModal(InputTextModal, {
                        value: ((_a = node === null || node === void 0 ? void 0 : node.nodeConfig) === null || _a === void 0 ? void 0 : _a.alias) || '',
                        label: 'New alias',
                        header: 'Set  alias',
                        onConfirm: newAlias => {
                            setConfig(cfg => setPerspectiveTableAlias(cfg, designerId, newAlias));
                        },
                    });
                },
            },
            (nodeConfig === null || nodeConfig === void 0 ? void 0 : nodeConfig.alias) && {
                text: 'Remove alias',
                onClick: () => setConfig(cfg => setPerspectiveTableAlias(cfg, designerId, null)),
            },
        ],
        nodeConfig &&
            config.rootDesignerId != designerId && [
            { divider: true },
            {
                text: 'Set root',
                onClick: () => __awaiter(this, void 0, void 0, function* () {
                    setConfig(cfg => (Object.assign(Object.assign({}, cfg), { rootDesignerId: designerId })));
                    yield tick();
                    if (config.isArranged) {
                        runCommand('designer.arrange');
                    }
                }),
            },
        ],
    ];
}
