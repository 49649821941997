"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFreeTableModel = void 0;
function createFreeTableModel() {
    return {
        structure: {
            columns: [
                {
                    columnName: 'col1',
                },
            ],
            foreignKeys: [],
        },
        rows: [
            {
                col1: 'val1',
            },
            {
                col1: 'val2',
            },
        ],
    };
}
exports.createFreeTableModel = createFreeTableModel;
