import { __awaiter } from "tslib";
import { apiCall } from '../utility/api';
export function saveDbToApp(conid, database, app) {
    return __awaiter(this, void 0, void 0, function* () {
        if (app == '#new') {
            const folder = yield apiCall('apps/create-folder', { folder: database });
            yield apiCall('connections/update-database', {
                conid,
                database,
                values: {
                    [`useApp:${folder}`]: true,
                },
            });
            return folder;
        }
        yield apiCall('connections/update-database', {
            conid,
            database,
            values: {
                [`useApp:${app}`]: true,
            },
        });
        return app;
    });
}
export function filterAppsForDatabase(connection, database, $apps) {
    const db = ((connection === null || connection === void 0 ? void 0 : connection.databases) || []).find(x => x.name == database);
    return $apps === null || $apps === void 0 ? void 0 : $apps.filter(app => db && db[`useApp:${app.name}`]);
}
