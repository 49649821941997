<script context="module">
  export const themeClassName = 'theme-light';
  export const themeName = 'Light';
  export const themeType = 'light';
</script>

<style>
  :global(.theme-light) {
    --theme-font-1: #262626;
    --theme-font-2: #4d4d4d;
    --theme-font-3: #808080;
    --theme-font-4: #b3b3b3;
    --theme-font-hover: #061178; /* blue-9 */
    --theme-font-link: #10239e; /* blue-8 */
    --theme-font-alt: #135200; /* green-9 */

    --theme-bg-0: #fff;
    --theme-bg-1: #ededed;
    --theme-bg-2: #d4d4d4;
    --theme-bg-3: #bbbbbb;
    --theme-bg-4: #a2a2a2;
    --theme-bg-alt: #f0f5ff;

    --theme-bg-gold: #fff1b8; /* gold-2 */
    --theme-bg-orange: #ffe7ba; /*orange-2 */
    --theme-bg-green: #d9f7be; /* green-2 */
    --theme-bg-volcano: #ffd8bf; /* volcano-2 */
    --theme-bg-red: #ffccc7; /* red-2 */
    --theme-bg-blue: #91d5ff; /* blue-3 */
    --theme-bg-magenta: #ffadd2; /* magenta-3 */

    --theme-font-inv-1: #ffffff;
    --theme-font-inv-15: #dedede;
    --theme-font-inv-2: #b3b3b3;
    --theme-font-inv-3: #808080;
    --theme-font-inv-4: #4d4d4d;

    --theme-bg-inv-1: #222;
    --theme-bg-inv-2: #3c3c3c;
    --theme-bg-inv-3: #565656;
    --theme-bg-inv-4: #707070;

    --theme-border: #ccc;

    --theme-bg-hover: #bae7ff;
    --theme-bg-selected: #91d5ff; /* blue-3 */
    --theme-bg-selected-point: #40a9ff; /* blue-5 */
    

    --theme-bg-statusbar-inv: #0050b3;
    --theme-bg-statusbar-inv-hover: #096dd9;
    --theme-bg-statusbar-inv-font: #222;
    --theme-bg-statusbar-inv-bg: #ccc;
    --theme-bg-modalheader: #eff;

    --theme-bg-button-inv: #337ab7;
    --theme-bg-button-inv-2: #4d8bc0;
    --theme-bg-button-inv-3: #679cc9;

    --theme-icon-blue: #096dd9; /* blue-7 */
    --theme-icon-green: #237804; /* green-7 */
    --theme-icon-red: #cf1322; /* red-7 */
    --theme-icon-gold: #d48806; /* gold-7 */
    --theme-icon-yellow: #d4b106; /* yellow-7 */
    --theme-icon-magenta: #c41d7f; /* magenta-7 */

    --theme-icon-inv-green: #8fd460;
    --theme-icon-inv-red: #e84749;

  }
</style>
